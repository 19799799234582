<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-btn class="msaBlue white--text" @click="launchLMS()">
          Launch LMS Builder
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'TrainingCourses',
  methods: {
    async launchLMS() {
      var windowReference = window.open();

      let respose = await this.$axios.post('get-transfer-token?format=json', {
        loaderText: 'Connecting....',
      });
      const url =
        process.env.VUE_APP_BASE_URL +
        'security-service/cht-to-lms?format=json&token=' +
        respose.data;
      windowReference.location = url;
    },
  },
  beforeMount() {
    // pinging the server to form a session
    this.$axios.post('public/ping?format=json', {});
  },
};
</script>

<style></style>
